import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap"; // Import Table from Reactstrap
import axios from "axios";
import moment from "moment";
import GetUserDetail from "./GetuserDetail";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { FaDownload } from "react-icons/fa";

const UserList = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10); // Default limit value
  const [page, setPage] = useState(1); // Default page number
  const [maxRow, setMaxRow] = useState(0); // max number of rows
  const [dateRange, setDateRange] = useState(null); // Date range state

  useEffect(() => {
    const Token = localStorage.getItem("accessToken");
    const fromDate = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYY-MM-DD") : "";
    const toDate = dateRange && dateRange[1] ? moment(dateRange[1]).format("YYYY-MM-DD") : "";

    axios
      .get(
        `${process.env.REACT_APP_DEV_URL}/admin_panel/v3.8/user/getAllUsers?limit=${limit}&page=${page}&fromDate=${fromDate}&toDate=${toDate}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response) => {
        setApiData(response?.data?.data?.Schools);
        setMaxRow(Math.ceil(response?.data?.data?.length / limit));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [limit, page, dateRange]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleExportCSV = () => {
    const headers = [
      "Name",
      "Email",
      "Gender",
      "Role Name",
      "Active Subscription Plan",
      "Created At",
    ];

    const rows = apiData.map((user) => [
      `${user?.First_name} ${user?.Last_name}`,
      user?.Email,
      user?.Gender,
      user?.RoleName,
      (user?.subscription[0]?.SubscriptionPlan && user?.subscription[0]?.SubscriptionPlan) ||
        user?.subscription?.SubscriptionPlan ||
        "Freemium",
      moment(user?.createdAt).format("MMMM Do YYYY"),
    ]);

    let csvContent = "data:text/csv;charset=utf-8," + [headers.join(","), ...rows.map(e => e.join(","))].join("\n");
    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users_data.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-end">
          <DateRangePicker onChange={setDateRange} value={dateRange} />
          <Button onClick={handleExportCSV} color="primary" className="rounded-pill ms-3">
           Export CSV <FaDownload />
          </Button>
        </div>
        <Table responsive striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Gender</th>
              <th>Role Name</th>
              <th>Active Subscription Plan</th>
              <th>Created At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {apiData.map((user, index) => (
              <tr key={user?._id}>
                <td>{`${user?.First_name} ${user?.Last_name}`}</td>
                <td>{user?.Email}</td>
                <td>{user?.Gender}</td>
                <td>{user?.RoleName}</td>
                <td>
                  <span className="text-capitalize">
                    {(user?.subscription[0]?.SubscriptionPlan &&
                      user?.subscription[0]?.SubscriptionPlan) ||
                      user?.subscription?.SubscriptionPlan ||
                      "Freemium"}
                  </span>
                </td>
                <td>{moment(user?.createdAt).format("MMMM Do YYYY")}</td>
                <td>
                  <GetUserDetail user={user} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {maxRow >= "9" && 
        <Pagination className="d-flex justify-content-center">
          <PaginationItem disabled={page === 1}>
            <PaginationLink onClick={() => handlePageChange(page - 1)} previous />
          </PaginationItem>
          {[...Array(maxRow).keys()].map(
            (pageNumber) =>
              (pageNumber === page) && (
                <PaginationItem key={pageNumber} active={pageNumber === page}>
                  <PaginationLink onClick={() => handlePageChange(pageNumber + 1)}>
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              )
          )}
          <PaginationItem disabled={page === maxRow}>
            <PaginationLink onClick={() => handlePageChange(page + 1)} next />
          </PaginationItem>
        </Pagination>}
      </Container>
    </div>
  );
};

export { UserList };
