import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import toast from "react-hot-toast";

const UserRequest = () => {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1); // Default page number
  const [maxRow, setMaxRow] = useState(0); // max number of rows
  const limit = "10";

  useEffect(() => {
    const Token = localStorage.getItem("accessToken");

    axios
      .get(
        `${process.env.REACT_APP_DEV_URL}/admin-requests?pageLimit=${limit}&pageNumber=${page}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response) => {
        setApiData(response?.data?.data);
        // setMaxRow(Math.ceil(response?.data?.data?.length / limit));
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
    axios
      .get(`${process.env.REACT_APP_DEV_URL}/admin-requests/count`, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
      .then((response) => {
        setMaxRow(Math.ceil(response?.data?.data / limit));
      });
  }, [limit, page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleApprove = (schoolId) => {
    const Token = localStorage.getItem("accessToken");

    axios
      .patch(
        `${process.env.REACT_APP_DEV_URL}/admin-requests/${schoolId}`,
        { responseType: "accepted" },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response) => {
        console.log("Request approved:", response.data);
        toast.success(response?.data?.message);
        setApiData((prevData) =>
          prevData.map((request) =>
            request._id === schoolId
              ? { ...request, status: "accepted" }
              : request
          )
        );
      })
      .catch((error) => {
        console.error("Error approving request:", error);
        toast.error(error?.response?.data?.message);
      });
  };

  const handleReject = (schoolId) => {
    const Token = localStorage.getItem("accessToken");

    axios
      .patch(
        `${process.env.REACT_APP_DEV_URL}/admin-requests/${schoolId}`,
        { responseType: "rejected" },
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      )
      .then((response) => {
        toast.success(response?.data?.message);
        console.log("Request rejected:", response.data);
        setApiData((prevData) =>
          prevData.map((request) =>
            request._id === schoolId
              ? { ...request, status: "rejected" }
              : request
          )
        );
      })
      .catch((error) => {
        console.error("Error rejecting request:", error);
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Admin Name</th>
              <th>School Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>License Start</th>
              <th>License End</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {apiData.map((user) => (
              <tr key={user?._id}>
                <td>{`${user?.schoolDetails?.PrincipalFirstName}`}</td>
                <td>{user?.schoolDetails?.SchoolName}</td>
                <td>{user?.schoolDetails?.SchoolEmailID}</td>
                <td className="text-capitalize">{user?.status}</td>
                <td>
                  {moment(user?.schoolDetails?.license?.startDate).format(
                    "MMMM Do YYYY"
                  )}
                </td>
                <td>
                  {moment(user?.schoolDetails?.license?.endDate).format(
                    "MMMM Do YYYY"
                  )}
                </td>
                <td>
                  <Button
                    color="success"
                    onClick={() => handleApprove(user?._id)}
                    disabled={
                      user?.status === "accepted" || user?.status === "rejected"
                    }
                  >
                    <FaCheck />
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={() => handleReject(user?._id)}
                    disabled={
                      user?.status === "accepted" || user?.status === "rejected"
                    }
                  >
                    <ImCross />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination className="d-flex justify-content-center">
          <PaginationItem disabled={page === 1}>
            <PaginationLink
              onClick={() => handlePageChange(page - 1)}
              previous
            />
          </PaginationItem>
          <PaginationLink>{page} </PaginationLink>
          <PaginationItem disabled={page === maxRow}>
            <PaginationLink onClick={() => handlePageChange(page + 1)} next />
          </PaginationItem>
        </Pagination>
      </Container>
    </div>
  );
};

export { UserRequest };
