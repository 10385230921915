import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Draft.css"; // Assuming custom CSS is in this file
import axios from "axios";
import { MdNavigateNext } from "react-icons/md";
import { GrFormPrevious } from "react-icons/gr";
import moment from "moment";
import { IoIosSend } from "react-icons/io";

const Draft = ({ handleMarketing, setSelectedDraft, SetMarketingPage }) => {
  const [expandedCard, setExpandedCard] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [draftMsg, setDraftMsg] = useState([]);
  const [sentMsg, setSentMsg] = useState([]);
  const [draftPage, setDraftPage] = useState(1);
  const [sentPage, setSentPage] = useState(1);

  const handleCardClick = (card) => {
    setExpandedCard(expandedCard === card ? null : card);
  };

  const fetchDraftAndSent = async () => {
    setLoading(true);
    const Token = localStorage.getItem("accessToken");
    const draftUrl = `${process.env.REACT_APP_DEV_URL}/admin-marketing/overallListing?category=draft&pageNumber=${draftPage}`;
    const sentUrl = `${process.env.REACT_APP_DEV_URL}/admin-marketing/overallListing?category=sent&pageNumber=${sentPage}`;
    try {
      const [draftResponse, sentResponse] = await Promise.all([
        axios.get(draftUrl, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }),
        axios.get(sentUrl, {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }),
      ]);
      setDraftMsg(draftResponse.data.data || []);
      setSentMsg(sentResponse.data.data || []);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDraftAndSent();
  }, [draftPage, sentPage]);

  //   function handleDataset(event) {
  //     event.stopPropagation();
  //     window.alert("alert");
  //   }

  const renderCardContent = (data) => (
    <>
      {data.notification_title.map((title, i) => (
        <div key={i}>
          <CardTitle tag="h5">{title}</CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {data.notification_message[i]}
          </CardSubtitle>
        </div>
      ))}
    </>
  );

  return (
    <div className="container">
      <Row>
        <Col
          md={
            expandedCard === "draft" ? 12 : expandedCard === "history" ? 0 : 6
          }
          className={`transition-col ${
            expandedCard === null ? "hover-col" : ""
          }`}
          style={{ display: expandedCard === "history" ? "none" : "block" }}
          onClick={() => handleCardClick("draft")}
        >
          <div
            className="border rounded border-dark card-container scrollable-div"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <div className="w-100">
              <strong>Draft Message</strong>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              draftMsg.map((draftData, index) => (
                <Card
                  key={index}
                  className={`mb-2 pb-1 col-md-${
                    expandedCard === "draft" ? "4" : "6"
                  }`}
                >
                  <span className="cardTiming">
                    {moment(draftData?.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}{" "}
                  </span>
                  <CardBody
                    className={`${
                      expandedCard === "draft" ? "cardNormal" : "cardHight"
                    }`}
                  >
                    {renderCardContent(draftData)}
                  </CardBody>
                  {expandedCard === "draft" && (
                    <div className="text-center">
                      <Button
                        outline
                        color="success"
                        className="w-25"
                        type="button"
                        onClick={() => {
                          setSelectedDraft(draftData);
                          SetMarketingPage(true);
                        }}
                      >
                         Send <IoIosSend />
                      </Button>
                    </div>
                  )}
                </Card>
              ))
            )}
          </div>
          {expandedCard === "draft" && (
            <div className="pagination-controls">
              <Button
                disabled={draftPage <= 1}
                onClick={() => setDraftPage((prev) => Math.max(prev - 1, 1))}
              >
                <GrFormPrevious />
              </Button>
              <Button onClick={() => setDraftPage((prev) => prev + 1)}>
                <MdNavigateNext />
              </Button>
            </div>
          )}
        </Col>

        <Col
          md={
            expandedCard === "history" ? 12 : expandedCard === "draft" ? 0 : 6
          }
          className={`transition-col ${
            expandedCard === null ? "hover-col" : ""
          }`}
          style={{ display: expandedCard === "draft" ? "none" : "block" }}
          onClick={() => handleCardClick("history")}
        >
          <div
            className="border rounded border-dark card-container scrollable-div"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <div className="w-100">
              <strong>Sent Message</strong>
            </div>

            {isLoading ? (
              <Spinner />
            ) : (
              sentMsg.map((sentData, index) => (
                <Card
                  key={index}
                  className={`mb-2 pb-1 col-md-${
                    expandedCard === "history" ? "4" : "6"
                  }`}
                >
                  <span className="cardTiming">
                    {moment(sentData?.createdAt).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}{" "}
                  </span>
                  <CardBody
                    className={`${
                      expandedCard === "draft" ? "cardNormal" : "cardHight"
                    }`}
                  >
                    {renderCardContent(sentData)}
                  </CardBody>
                </Card>
              ))
            )}
          </div>
          {expandedCard === "history" && (
            <div className="pagination-controls">
              <Button
                disabled={sentPage <= 1}
                onClick={() => setSentPage((prev) => Math.max(prev - 1, 1))}
              >
                Previous
              </Button>
              <Button onClick={() => setSentPage((prev) => prev + 1)}>
                Next
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export { Draft };
