import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import toast from "react-hot-toast";
import EmojiPicker from "emoji-picker-react";
import { FilterListOff } from "@mui/icons-material";
import { Draft } from "./Draft";
import { IoIosSend } from "react-icons/io";
import { IoMdArrowRoundBack } from "react-icons/io";
import { MdSaveAlt } from "react-icons/md";

const languages = [
  { name: "english", label: "English", code: "default" },
  { name: "hindi", label: "Hindi", code: "91" },
  { name: "chinese", label: "Chinese", code: "86" },
  { name: "arabic", label: "Arabic", code: "971" },
  { name: "japanese", label: "Japanese", code: "81" },
  { name: "spanish", label: "Spanish", code: "34" },
  { name: "french", label: "French", code: "33" },
];

const Marketing = () => {
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [formData, setFormData] = useState({
    option: "",
    country_code: "",
    gender: "",
    role: "",
    subscription: "",
    tubclassusers: "",
    notification_message: {},
    notification_title: {},
    languages: [],
  });

  const [isLoading, setLoading] = useState(false);
  const [isLoadings, setLoadings] = useState(false);
  const [marketingPage, SetMarketingPage] = useState(false);
  const [enabledLanguages, setEnabledLanguages] = useState(
    languages.reduce((acc, lang) => {
      acc[lang.name] = lang.name === "english";
      return acc;
    }, {})
  );

  useEffect(() => {
    if (selectedDraft) {
      const newFormData = {
        ...formData,
        notification_message: {},
        notification_title: {},
        languages: selectedDraft.languages,
      };

      selectedDraft.languages.forEach((lang, index) => {
        const languageName = languages.find((l) => l.code === lang)?.name;
        if (languageName) {
          newFormData.notification_message[languageName] =
            selectedDraft.notification_message[index];
          newFormData.notification_title[languageName] =
            selectedDraft.notification_title[index];
        }
      });

      setFormData(newFormData);
      setEnabledLanguages(
        selectedDraft.languages.reduce(
          (acc, langCode) => {
            const language = languages.find((l) => l.code === langCode);
            if (language) acc[language.name] = true;
            return acc;
          },
          { english: true }
        )
      );
    }
  }, [selectedDraft]);

  const [showPickers, setShowPickers] = useState({});
  const [currentInput, setCurrentInput] = useState("");

  const handleChange = (e) => {
    const { name, value, dataset } = e.target;
    const lang = dataset.language;
    setFormData((prevFormData) => {
      const newMessages = { ...prevFormData.notification_message };
      const newTitles = { ...prevFormData.notification_title };
      if (name.startsWith("notification_message_")) {
        newMessages[lang] = value;
      } else if (name.startsWith("notification_title_")) {
        newTitles[lang] = value;
      } else {
        return { ...prevFormData, [name]: value };
      }
      return {
        ...prevFormData,
        notification_message: newMessages,
        notification_title: newTitles,
      };
    });
  };

  const handlePhoneChange = (value, country) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      country_code: country.dialCode,
    }));

    const matchingLanguage = languages.find(
      (lang) => lang.code.toString() === country.dialCode
    );

    if (matchingLanguage) {
      setEnabledLanguages({
        english: true,
        [matchingLanguage.name]: true,
      });
    } else if (!country.dialCode) {
      setEnabledLanguages(
        languages.reduce((acc, lang) => {
          acc[lang.name] = lang.name === "english";
          return acc;
        }, {})
      );
    } else {
      setEnabledLanguages({
        english: true,
      });
    }
  };

  const handleFormSubmit = async (e, isSubmit) => {
    (() => {
      isSubmit ? setLoadings(true) : setLoading(true);
    })();
    const Token = localStorage.getItem("accessToken");
    const notification_message = [];
    const notification_title = [];

    languages.forEach((lang) => {
      if (enabledLanguages[lang.name]) {
        notification_message.push(
          formData.notification_message[lang.name] || ""
        );
        notification_title.push(formData.notification_title[lang.name] || "");
      }
    });

    const finalData = {
      ...formData,
      notification_message,
      notification_title,
      languages: languages
        .filter((lang) => enabledLanguages[lang.name])
        .map((lang) => lang.code),
    };

    const url = isSubmit
      ? `${process.env.REACT_APP_DEV_URL}/admin-marketing`
      : `${process.env.REACT_APP_DEV_URL}/admin-marketing/savedraft`;

    try {
      const response = await axios.post(url, finalData, {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      });
      setLoading(false);
      setLoadings(false);
      toast.success(response?.data?.message);
    } catch (error) {
      setLoading(false);
      setLoadings(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name !== "english") {
      setEnabledLanguages((prev) => ({ ...prev, [name]: checked }));
    }
  };

  const handleClearFilters = () => {
    setFormData({
      option: "",
      country_code: "",
      gender: "",
      role: "",
      subscription: "",
      tubclassusers: "",
      notification_message: {},
      notification_title: {},
      languages: [],
    });
    setEnabledLanguages(
      languages.reduce((acc, lang) => {
        acc[lang.name] = lang.name === "english";
        return acc;
      }, {})
    );
  };

  const onEmojiClick = (event, emojiObject) => {
    setFormData((prevFormData) => {
      const newMessages = { ...prevFormData.notification_message };
      const newTitles = { ...prevFormData.notification_title };

      if (currentInput.startsWith("notification_message_")) {
        newMessages[currentInput.replace("notification_message_", "")] =
          (newMessages[currentInput.replace("notification_message_", "")] ||
            "") + emojiObject.emoji;
      } else if (currentInput.startsWith("notification_title_")) {
        newTitles[currentInput.replace("notification_title_", "")] =
          (newTitles[currentInput.replace("notification_title_", "")] || "") +
          emojiObject.emoji;
      }

      return {
        ...prevFormData,
        notification_message: newMessages,
        notification_title: newTitles,
      };
    });
    setShowPickers((prev) => ({ ...prev, [currentInput]: false }));
  };

  const togglePicker = (inputName) => {
    setCurrentInput(inputName);
    setShowPickers((prev) => ({ ...prev, [inputName]: !prev[inputName] }));
  };

  const checkboxSelector = (language) => (
    <div
      key={language.name}
      className={`d-flex col-md-2 justify-content-center ${
        language.name === "english" ? "d-none" : ""
      } ${
        countryCode == "" ? "" : countryCode == language?.code ? "" : "d-none"
      }`}
    >
      <Input
        type="checkbox"
        id={`${language.name}Checkbox`}
        name={language.name}
        label={`Enable ${language.label}`}
        checked={enabledLanguages[language.name]}
        onChange={handleCheckboxChange}
        disabled={language.name === "english"}
      />
      <Label for={`notification_title_${language.name}`} className="ms-2">
        {language.label}
      </Label>
    </div>
  );

  const renderLanguageInput = (language) =>
    enabledLanguages[language.name] && (
      <div key={language.name} className="col-md-6">
        <Col md={11} className="mx-auto">
          <FormGroup>
            <Label for={`notification_title_${language.name}`} className="mt-2">
              ({language.label}): Notification Title
            </Label>
            <Input
              type="text"
              name={`notification_title_${language.name}`}
              id={`notification_title_${language.name}`}
              data-language={language.name}
              onChange={handleChange}
              required={language.name !== "english"}
              value={formData.notification_title[language.name] || ""}
              onClick={() =>
                setCurrentInput(`notification_title_${language.name}`)
              }
            />
            <div className="d-flex justify-content-end">
              <span
                className="emoji-icon fs-4"
                onClick={() =>
                  togglePicker(`notification_title_${language.name}`)
                }
                style={{
                  position: "relative",
                  bottom: "35px",
                  right: "10px",
                  color: "yellow",
                  cursor: "pointer",
                }}
              >
                😄
              </span>
            </div>
            {showPickers[`notification_title_${language.name}`] && (
              <EmojiPicker
                pickerStyle={{ width: "100%" }}
                onEmojiClick={onEmojiClick}
              />
            )}
            <Label for={`notification_message_${language.name}`}>
              Notification Message
            </Label>
            <Input
              type="textarea"
              name={`notification_message_${language.name}`}
              id={`notification_message_${language.name}`}
              data-language={language.name}
              onChange={handleChange}
              style={{ minHeight: "150px" }}
              required={language.name !== "english"}
              value={formData.notification_message[language.name] || ""}
              onClick={() =>
                setCurrentInput(`notification_message_${language.name}`)
              }
            />{" "}
            <div className="d-flex justify-content-end">
              <span
                className="emoji-icon fs-4"
                onClick={() =>
                  togglePicker(`notification_message_${language.name}`)
                }
                style={{
                  position: "relative",
                  bottom: "35px",
                  right: "10px",
                  color: "yellow",
                  cursor:"pointer"
                }}
              >
                😄
              </span>
            </div>
            {showPickers[`notification_message_${language.name}`] && (
              <EmojiPicker
                pickerStyle={{ width: "100%" }}
                onEmojiClick={onEmojiClick}
              />
            )}
          </FormGroup>
        </Col>
      </div>
    );

  const countryCode = formData.country_code;
  const handleMarketing = () => {
    SetMarketingPage(!marketingPage);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex justify-content-between mb-2">
          <h2>Marketing</h2>
          <Button outline onClick={handleMarketing} className="mb-2">
            {marketingPage == true ? (
              <>
                <IoMdArrowRoundBack /> Go back
              </>
            ) : (
              "Send Immediately"
            )}
          </Button>
        </div>
        {marketingPage == false && (
          <Draft
            handleMarketing={handleMarketing}
            setSelectedDraft={setSelectedDraft}
            SetMarketingPage={SetMarketingPage}
          />
        )}
        <Form className={`${marketingPage == true ? "" : "d-none"}`}>
          <Row form>
            <Col md={2}>
              <FormGroup>
                <Label for="option">Option</Label>
                <Input
                  type="select"
                  name="option"
                  id="option"
                  onChange={handleChange}
                  value={formData.option}
                >
                  <option value="">Select Option</option>
                  <option value={1}>Parents Not Connected to school</option>
                  <option value={2}>Parents Don't have a player</option>
                  <option value={3}>Parent Connected to TUB Class</option>
                  <option value={4}>
                    Teacher Does not have student added in App
                  </option>
                  <option value={5}>Teachers Connected to TUB Class</option>
                  <option value={6}>
                    Teachers Not connected to TUB Class but added players in App
                    only
                  </option>
                  <option value={7}>Students Not connected to TUB Class</option>
                  <option value={8}>Students Connected to TUB Class</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>Country</Label>
                <PhoneInput
                  enableSearch={true}
                  value={formData.country_code}
                  onChange={handlePhoneChange}
                  placeholder="Select Country"
                />
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="select"
                  name="gender"
                  id="gender"
                  onChange={handleChange}
                  value={formData.gender}
                >
                  <option value="">Select Gender</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="role">Role</Label>
                <Input
                  type="select"
                  name="role"
                  id="role"
                  onChange={handleChange}
                  value={formData.role}
                >
                  <option value="">Select Role</option>
                  <option value="Parent">Parent</option>
                  <option value="Teacher">Teacher</option>
                  <option value="School Admin">School Admin</option>
                  <option value="Student">Student</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="subscription">Subscription</Label>
                <Input
                  type="select"
                  name="subscription"
                  id="subscription"
                  onChange={handleChange}
                  value={formData.subscription}
                >
                  <option value="">Select Subscription</option>
                  <option value="Premium">Premium</option>
                  <option value="Freemium">Freemium</option>
                  <option value="Premium (TUBClass User)">
                    Premium (TUBClass User)
                  </option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label for="tubclassusers">TUB Class Users</Label>
                <Input
                  type="select"
                  name="tubclassusers"
                  id="tubclassusers"
                  onChange={handleChange}
                  value={formData.tubclassusers}
                >
                  <option value="">Select</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={1} className="align-content-center">
              <Button outline onClick={handleClearFilters}>
                <FilterListOff />
              </Button>
            </Col>
          </Row>
          <Row
            form
            className="d-flex pt-2 mb-2 bg-secondary text-white align-items-center"
          >
            {languages.map(checkboxSelector)}
          </Row>
          <Row form className="justify-content-around">
            {languages.map(renderLanguageInput)}
          </Row>
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              onClick={(e) => handleFormSubmit(e, false)}
              disabled={isLoading}
              className="me-2"
            >
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                <>
                  Save <MdSaveAlt />
                </>
              )}
            </Button>
            <Button
              color="success"
              onClick={(e) => handleFormSubmit(e, true)}
              disabled={isLoadings}
            >
              {isLoadings ? (
                <Spinner size="sm" />
              ) : (
                <>
                  Send <IoIosSend />
                </>
              )}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export { Marketing };
