const routers = [
  {
    path: "/",
    redirect: "/auth",
    auth: false,
  },
  {
    component: "StaticLayout",
    path: "/auth",
    auth: false,
    exact: false,
    redirect: "/auth/login",
    childrens: [
      {
        component: "Login",
        path: "/login",
        auth: false,
        exact: true,
      },
      {
        component: "ForgetPwd",
        path: "/forgot-password",
        auth: false,
        exact: true,
      },
      {
        component: "Register",
        path: "/register",
        auth: false,
        exact: true,
      },
      {
        component: "SetupNewPassword",
        path: "/setnewpassword",
        auth: false,
        exact: true,
      },
      {
        component: "Recoverpw",
        path: "/recoverpw",
        auth: false,
        exact: true,
      },
      {
        component: "Pages404",
        path: "/404",
        auth: false,
        exact: true,
      },
      {
        component: "Pages500",
        path: "/500",
        auth: false,
        exact: true,
      },
      {
        component: "LockScreen",
        path: "/lockscreen",
        auth: false,
        exact: true,
      },
    ],
  },
  {
    component: "AdminLayout",
    path: "/staff",
    auth: true,
    exact: false,
    childrens: [
      {
        component: "UserProfile",
        path: "/profile",
        auth: false,
        exact: true,
      },
      {
        component: "WordsList",
        path: "/words",
        auth: true,
        exact: true,
      },
      {
        component: "WordsDeletedList",
        path: "/word/deleted/list",
        auth: true,
        exact: true,
      },
      {
        component: "WordDetail",
        path: "/word/detail",
        auth: true,
        exact: true,
      },
      {
        component: "WordDetail",
        path: "/word/detail/:_id",
        auth: true,
        exact: true,
      },
      {
        component: "Tags",
        path: "/tags",
        auth: true,
        exact: true,
      },
      {
        component: "Curriculum",
        path: "/curriculum",
        auth: true,
        exact: true,
      },
      {
        component: "CurriculumReadingProgramList",
        path: "/curriculum/readingprogram/:_id",
        auth: true,
        exact: true,
      },
      {
        component: "ReadingProgramList",
        path: "/readingprograms",
        auth: true,
        exact: true,
      },
      {
        component: "ReadingProgramDetail",
        path: "/readingprogram/detail",
        auth: true,
        exact: true,
      },
      {
        component: "ReadingProgramDetail",
        path: "/readingprogram/detail/:_id",
        auth: true,
        exact: true,
      },
      {
        component: "UserProfile",
        path: "/profile",
        auth: true,
        exact: true,
      },

      {
        component: "PacksList",
        path: "/packs",
        auth: true,
        exact: true,
      },
      {
        component: "PackDetailList",
        path: "/pack/detail/list/:_id",
        auth: true,
        exact: true,
      },
      {
        component: "PackDetail",
        path: "/pack/detail",
        auth: true,
        exact: true,
      },
      {
        component: "PackDetail",
        path: "/pack/detail/:_id",
        auth: true,
        exact: true,
      },

      {
        component: "TubsheetList",
        path: "/tubsheets",
        auth: true,
        exact: true,
      },
      {
        component: "TubsheetDetail",
        path: "/sheet/detail",
        auth: true,
        exact: true,
      },
      {
        component: "TubsheetDetail",
        path: "/sheet/detail/:_id",
        auth: true,
        exact: true,
      },
      {
        component: "TubcardList",
        path: "/tubcards",
        auth: true,
        exact: true,
      },
      {
        component: "TubcardDetail",
        path: "/card/detail",
        auth: true,
        exact: true,
      },
      {
        component: "TubcardDetail",
        path: "/card/detail/:_id",
        auth: true,
        exact: true,
      },
      {
        component: "AddSchool",
        path: "/school",
        auth: true,
        exact: true,
      },
      {
        component: "SchoolListing",
        path: "/schoolList/:id",
        auth: true,
        exact: true,
      },
      {
        component: "AddUser",
        path: "/user",
        auth: true,
        exact: true,
      },
      {
        component: "UserList",
        path: "/user-List",
        auth: true,
        exact: true,
      },
      {
        component: "UserRequest",
        path: "/user-request",
        auth: true,
        exact: true,
      },
      {
        component: "Director",
        path: "/director",
        auth: true,
        exact: true,
      },
      {
        component: "Hierarchy",
        path: "/hierarchy",
        auth: true,
        exact: true,
      },
      {
        component: "DirectorDetail",
        path: "/DirectorDetail/:id",
        auth: true,
        exact: true,
      },
      {
        component: "MapContentsToUser",
        path: "/mapcontent/user",
        auth: true,
        exact: true,
      },
      {
        component: "Category",
        path: "/category",
        auth: true,
        exact: true,
      },
      {
        component: "OrdersList",
        path: "/orders",
        auth: true,
        exact: true,
      },
      {
        component: "OrderDetail",
        path: "/orders/order/detail",
        auth: true,
        exact: true,
      },
      {
        component: "Customers",
        path: "/customers",
        auth: true,
        exact: true,
      },
      {
        component: "Coupons",
        path: "/coupons",
        auth: true,
        exact: true,
      },
      {
        component: "CouponDetail",
        path: "/coupons/coupon/detail",
        auth: true,
        exact: true,
      },
      {
        component: "PacksInCategoryList",
        path: "/category/pack/list",
        auth: true,
        exact: true,
      },
      {
        component: "SubscriptionRuleList",
        path: "/subscriptions/rules",
        auth: true,
        exact: true,
      },
      {
        component: "RuleDetail",
        path: "/subscriptions/rules/detail",
        auth: true,
        exact: true,
      },
      {
        component: "RuleDetail",
        path: "/subscriptions/rules/detail/:id",
        auth: true,
        exact: true,
      },
      {
        component: "DealsList",
        path: "/deals/list",
        auth: true,
        exact: true,
      },
      {
        component: "DealDetail",
        path: "/deal/detail",
        auth: true,
        exact: true,
      },
      {
        component: "DealDetail",
        path: "/deal/detail/:id",
        auth: true,
        exact: true,
      },
      {
        component: "ComboList",
        path: "/combo/list",
        auth: true,
        exact: true,
      },
      {
        component: "ComboDetail",
        path: "/combo/detail",
        auth: true,
        exact: true,
      },
      {
        component: "ComboDetail",
        path: "/combo/detail/:id",
        auth: true,
        exact: true,
      },
      {
        component: "Marketing",
        path: "/marketing",
        auth: true,
        exact: true,
      },
    ],
  },
];

export default routers;
